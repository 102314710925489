// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-book-a-demo-js": () => import("./../../../src/pages/book-a-demo.js" /* webpackChunkName: "component---src-pages-book-a-demo-js" */),
  "component---src-pages-brandcrush-for-business-js": () => import("./../../../src/pages/brandcrush-for-business.js" /* webpackChunkName: "component---src-pages-brandcrush-for-business-js" */),
  "component---src-pages-casestudies-js": () => import("./../../../src/pages/casestudies.js" /* webpackChunkName: "component---src-pages-casestudies-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fa-qs-js": () => import("./../../../src/pages/FAQs.js" /* webpackChunkName: "component---src-pages-fa-qs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-termsofservice-js": () => import("./../../../src/pages/termsofservice.js" /* webpackChunkName: "component---src-pages-termsofservice-js" */),
  "component---src-templates-agencies-js": () => import("./../../../src/templates/agencies.js" /* webpackChunkName: "component---src-templates-agencies-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-brandcrush-for-business-corporate-coworking-js": () => import("./../../../src/templates/brandcrush-for-business/corporate-coworking.js" /* webpackChunkName: "component---src-templates-brandcrush-for-business-corporate-coworking-js" */),
  "component---src-templates-brandcrush-for-business-d-2-c-retail-food-delivery-js": () => import("./../../../src/templates/brandcrush-for-business/d2c-retail-food-delivery.js" /* webpackChunkName: "component---src-templates-brandcrush-for-business-d-2-c-retail-food-delivery-js" */),
  "component---src-templates-brandcrush-for-business-health-wellness-js": () => import("./../../../src/templates/brandcrush-for-business/health-wellness.js" /* webpackChunkName: "component---src-templates-brandcrush-for-business-health-wellness-js" */),
  "component---src-templates-brandcrush-for-business-hospitality-tourism-js": () => import("./../../../src/templates/brandcrush-for-business/hospitality-tourism.js" /* webpackChunkName: "component---src-templates-brandcrush-for-business-hospitality-tourism-js" */),
  "component---src-templates-brandcrush-for-business-retail-grocery-download-overview-js": () => import("./../../../src/templates/brandcrush-for-business/retail-grocery/download-overview.js" /* webpackChunkName: "component---src-templates-brandcrush-for-business-retail-grocery-download-overview-js" */),
  "component---src-templates-brandcrush-for-business-retail-grocery-index-js": () => import("./../../../src/templates/brandcrush-for-business/retail-grocery/index.js" /* webpackChunkName: "component---src-templates-brandcrush-for-business-retail-grocery-index-js" */),
  "component---src-templates-brandcrush-for-business-shopping-centres-malls-js": () => import("./../../../src/templates/brandcrush-for-business/shopping-centres-malls.js" /* webpackChunkName: "component---src-templates-brandcrush-for-business-shopping-centres-malls-js" */),
  "component---src-templates-brandcrush-for-business-universities-js": () => import("./../../../src/templates/brandcrush-for-business/universities.js" /* webpackChunkName: "component---src-templates-brandcrush-for-business-universities-js" */),
  "component---src-templates-brands-js": () => import("./../../../src/templates/brands.js" /* webpackChunkName: "component---src-templates-brands-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/case-studies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-case-study-article-js": () => import("./../../../src/templates/case-study-article.js" /* webpackChunkName: "component---src-templates-case-study-article-js" */),
  "component---src-templates-partners-js": () => import("./../../../src/templates/partners.js" /* webpackChunkName: "component---src-templates-partners-js" */)
}

